<template>
  <div>
    <div 
      id="data-list-list-view" 
      class="data-list-container">
      <custom-table
        ref="table"
        :max-items="itemsPerPage"
        :searchKeys="searchKeys"
        :data="items"
        pagination
        search
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse items-center flex-grow justify-between"
        >
          <div class="flex flex-wrap-reverse items-center data-list-btn-container">
            <!-- ADD NEW -->
            <div
              class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
              @click="createData"
            >
              <feather-icon 
                icon="PlusIcon" 
                svg-classes="h-4 w-4" />
              <span class="ml-2 text-base text-primary">Add Zone</span>
            </div>
            <vs-dropdown 
              vs-custom-content 
              vs-trigger-click 
              class="cursor-pointer mb-4">
              <span class="cursor-pointer flex items-center i18n-locale">
                <img
                  :src="
                    require(`@/assets/images/flags/${$store.state.locale.currentLang}.png`)
                  "
                  :alt="$store.state.locale.currentLang"
                  class="h-4 w-5"
                >
                <span class="hidden sm:block ml-2 uppercase">{{
                  $store.state.locale.currentLang
                }}</span>
              </span>
              <vs-dropdown-menu class="w-48 i18n-dropdown vx-navbar-dropdown">
                <vs-dropdown-item @click="updateLang('th')">
                  <img 
                    class="h-4 w-5 mr-1" 
                    src="@/assets/images/flags/th.png" 
                    alt="th" >
                  &nbsp;Thai
                </vs-dropdown-item>
                <vs-dropdown-item @click="updateLang('en')">
                  <img 
                    class="h-4 w-5 mr-1" 
                    src="@/assets/images/flags/en.png" 
                    alt="en" >
                  &nbsp;English
                </vs-dropdown-item>
                <vs-dropdown-item @click="updateLang('cn')">
                  <img 
                    class="h-4 w-5 mr-1" 
                    src="@/assets/images/flags/cn.png" 
                    alt="cn" >
                  &nbsp;Chinese
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <div>
            <LhDropdownPerPage 
              :items="items" 
              :items-per-page="itemsPerPage" 
              :current-page="currentPage" 
              :queried-items="queriedItems"
              @click="(page) => itemsPerPage = page"
            /> 

            <!-- FILTER -->
            <vs-dropdown
              vs-trigger-click
              class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2">{{
                  typeFilter === 'flm'
                    ? 'FLM'
                    : typeFilter === 'website'
                      ? 'Website'
                      : 'All Type'
                }}</span>
                <feather-icon 
                  icon="ChevronDownIcon" 
                  svg-classes="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="typeFilter = null">
                  <span>All Type</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="typeFilter = 'flm'">
                  <span>FLM</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="typeFilter = 'website'">
                  <span>Website</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </div>
        <template slot="thead">
          <vs-th sort-key="title">โซน</vs-th>
          <vs-th sort-key="province">จังหวัด</vs-th>
          <vs-th sort-key="type">การใช้งาน</vs-th>
          <vs-th sort-key="updated_at">วันที่แก้ไขล่าสุด</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr 
              v-for="(tr, indextr) in data" 
              :data="tr" 
              :key="indextr">
              <td class="td vs-table--td" @click.stop="editData(tr.id)">{{ tr.title }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">{{ tr.province }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">{{ tr.type === 'flm' ? 'FLM' : 'เว็บไซต์' }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">
                <p class="brand-priority">
                  {{ updatedAtDatetimeFormat(tr.updated_at) }}
                </p>
              </td>
              <vs-td class="whitespace-no-wrap cursor-default">
                <router-link
                  :to="{ name: `${routePrefix}-edit`, params: { id: tr.id }}"
                  class="pt-2 color-text-link"
                >
                  <feather-icon
                    icon="EditIcon"
                    svg-classes="w-5 h-5 hover:text-primary stroke-current"
                  />
                </router-link>
                <feather-icon
                  icon="TrashIcon"
                  svg-classes="w-5 h-5 hover:text-danger stroke-current"
                  class="ml-2 cursor-pointer"
                  @click.stop="deleteData(tr.id)"
                />
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { computed, ref, watch } from '@vue/composition-api'
import map from 'lodash/map'
import find from 'lodash/find'
import filter from 'lodash/filter'
import useCrud from '@/use/useCrud'
import useDatepicker from '@/use/useDatepicker'
import LhDropdownPerPage from '@/components/LhDropdownPerPage'
import CustomTable from '@/components/CustomTable'

export default {
  name: 'index',
  components: {
    LhDropdownPerPage,
    CustomTable
  },
  setup(props, ctx) {
    const {
      root: { $store },
    } = ctx
    const crudFunction = useCrud(ctx, 'zone')
    const { moment, updatedAtDatetimeFormat } = useDatepicker()
    const typeFilter = ref(null)

    crudFunction.routePrefix.value = 'property-zone'

    const items = computed(() => {
      let data = crudFunction.result.value
      if (typeFilter.value !== null) {
        data = filter(data, ['type', typeFilter.value])
      }

      return map(data, (item) => {
        const lang = $store.state.locale.currentLang
        const zone = find(item.zone_translates, ['lang', lang])
        const province = find(item.province.province_translates, ['lang', lang])
        return {
          id: item.id,
          type: item.type === 'website' ? 'เว็บไซต์' : item.type,
          title: zone ? zone.value : item.zone_translates[0].value,
          province: province
            ? province.value
            : item.province.province_translates[0].value,
          updated_at: item.updated_at,
        }
      })
    })

    const updateLang = (value) => {
      ctx.emit('update-lang', value)
      ctx.root.$store.dispatch('locale/changeLang', value)
    }

    const queriedItems = computed(() => {
      return refs.table ? refs.table.queriedResults.length : items.value.length
    })

    const editData = (id) => {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      ctx.root.$router.push({
        name: `${crudFunction.routePrefix.value}-edit`,
        params: { id },
      })
    }

    return {
      ...crudFunction,
      itemsPerPage: 10,
      items,
      updateLang,
      updatedAtDatetimeFormat,
      typeFilter,
      editData,
      searchKeys: ['title', 'province', 'type']
    }
  },
}
</script>

<style scoped></style>
